import React, { useEffect, useRef, useState } from 'react'
import { navigate, graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import UsedProductSquare from 'components/product-squares/product-square-used'
import Pagination from 'components/pagination'
import removeEmptyKeys from 'utils/remove-empty-keys'
import QuickSearch from 'components/used-equipment/quick-search'
import Filter from 'components/filter'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import UnstyledButton from 'components/button/unstyled-button'
import { useEffectOnce } from 'hooks/use-effect-once'
import auger from 'object-auger'
import P from 'components/new/typography/p'
import Disclaimers from 'components/new/disclaimers'

const parseSearchParams = searchParamsStr => {
  const parsed = {}
  const params = new URLSearchParams(searchParamsStr)

  for (let [key, value] of params.entries()) {
    // Skip params with no value
    if (!value) {
      continue
    }

    // Convert value to proper type
    // Number
    if (!/[^\d]/.test(value)) {
      value = Number(value)
    }

    if (!parsed.hasOwnProperty(key)) {
      parsed[key] = value
    } else {
      parsed[key] = [parsed[key], value]
    }
  }

  return parsed
}

const filterProp = (arr, prop, val) => {
  const propPath = Array.isArray(prop) ? prop : [prop]
  return arr.filter(obj => auger.get(obj, propPath) === val)
}

const sortOptions = {
  'Relevance': equipment => {
    return equipment
  },
  'Recently Added': equipment => {
    return equipment.sort((a, b) => {
      if (a.modifiedDate < b.modifiedDate) return 1
      if (a.modifiedDate > b.modifiedDate) return -1
      return null
    })
  },
  'Price: Low - High': equipment => {
    return equipment.sort((a, b) => {
      if (a.price < b.price) return -1
      if (a.price > b.price) return 1
      return null
    })
  },
  'Price: High - Low': equipment => {
    return equipment.sort((a, b) => {
      if (a.price > b.price) return -1
      if (a.price < b.price) return 1
      return null
    })
  },
  'Engine Hours: Low - High': equipment => {
    return equipment.sort((a, b) => {
      if (a.engineHoursMeter < b.engineHoursMeter) return -1
      if (a.engineHoursMeter > b.engineHoursMeter) return 1
      return null
    })
  },
  'Engine Hours: High - Low': equipment => {
    return equipment.sort((a, b) => {
      if (a.engineHoursMeter > b.engineHoursMeter) return -1
      if (a.engineHoursMeter < b.engineHoursMeter) return 1
      return null
    })
  },
  'Year: Newest - Oldest': equipment => {
    return equipment.sort((a, b) => {
      if (a.modelYear > b.modelYear) return -1
      if (a.modelYear < b.modelYear) return 1
      return null
    })
  },
  'Year: Oldest - Newest': equipment => {
    return equipment.sort((a, b) => {
      if (a.modelYear < b.modelYear) return -1
      if (a.modelYear > b.modelYear) return 1
      return null
    })
  },
}

const filterTitles = {
  location: 'Location',
  minHours: 'Min Hours',
  maxHours: 'Max Hours',
  condition: 'Condition',
}

const formatFilterValue = (val, type) => {
  switch (type) {
    case 'minHours':
    case 'maxHours':
      return val.toLocaleString('en-US')
    case 'location':
    default:
      return val
  }
}

const showingOptions = [10, 20, 30]

const UsedEquipmentPromotionTemplate = props => {
  const data = getInitialData(props)
  const [page, setPage] = useState(1)
  const [itemsShowing, setItemsShowing] = useState(10)
  const [sort, setSort] = useState('Recently Added')
  const [filter, setFilter] = useState({})
  const [search, setSearch] = useState('')
  const [equipment, setEquipment] = useState(data.equipment)
  const topEl = useRef(null)

  /* TEMPORARY HUT229 - BEGIN */
  const isHUT229 = props.pageContext._id === '5dc6bde0-87cb-4156-96f9-d1ca3782b46d'
  /* TEMPORARY HUT229 - END */

  const { meta = {}, pathname, sanityPromotion } = data

  useEffectOnce(() => {
    window.addEventListener('popstate', onBackButtonEvent)

    if (props.location.search) {
      const paramsObj = parseSearchParams(props.location.search)

      let { page, itemsShowing, search = '', sort, ...filters } = paramsObj

      onFilter(
        {
          ...filters,
          page,
          itemsShowing,
          sort,
          search,
        },
        false
      )
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  })

  const onBackButtonEvent = () => {
    const paramsObj = parseSearchParams(props.location.search)

    if (!paramsObj.hasOwnProperty('search')) {
      paramsObj.search = ''
    }

    onFilter(paramsObj, false)
  }

  const onFilter = async (filters, updateHistory = true) => {
    let { page = 1, itemsShowing = 10, sort: sortValue, search: searchVal, ...filter } = filters
    let equipment = data.equipment

    if (searchVal !== '') {
      const matchedEquipment = []
      const results = await onSearch(searchVal)
      results.forEach(res => {
        for (let i = 0; i < equipment.length; i++) {
          if (equipment[i].slug === res.slug) {
            matchedEquipment.push(equipment[i])
            break
          }
        }
      })
      equipment = matchedEquipment
    }

    if (!sortValue) {
      if (searchVal !== '') {
        sortValue = 'Relevance'
      } else {
        sortValue = sort
      }
    } else {
      equipment = sortOptions[sortValue](equipment)
    }

    if (itemsShowing) {
      itemsShowing = Number(itemsShowing)
    }

    if (filter.condition) {
      const isNewFilterCondition = filter.condition === 'New'
      equipment = equipment.filter(obj => obj.isNew === isNewFilterCondition)
    }

    if (filter.location) {
      equipment = filterProp(equipment, ['location', 'name'], filter.location)
    }

    if (filter.minHours > 0) {
      filter.minHours = Number(filter.minHours)

      equipment = equipment.filter(obj => filter.minHours <= obj.engineHoursMeter)
    }

    if (filter.maxHours) {
      filter.maxHours = Number(filter.maxHours)
      equipment = equipment.filter(obj => filter.maxHours >= obj.engineHoursMeter)
    }

    const newFilter = removeEmptyKeys(filter)

    setFilter(newFilter)
    setPage(page)
    setItemsShowing(itemsShowing)
    setSort(sortValue)
    setSearch(searchVal)
    setEquipment(equipment)

    if (updateHistory) {
      const q = {
        ...newFilter,
        page,
        itemsShowing,
        sort: sortValue,
      }

      if (searchVal) {
        q.search = searchVal
      }

      const params = new URLSearchParams(q)

      navigate(`${pathname}?${params.toString()}`)
    }
  }

  const setShowing = e => {
    const val = Number(e.target.innerText)
    if (val !== itemsShowing) {
      onFilter({
        ...filter,
        itemsShowing: val,
        sort,
        search: search || '',
      })
    }
  }

  const onSort = e => {
    const val = e.target.value || e.target.getAttribute('data-value')
    if (val !== sort) {
      onFilter({
        ...filter,
        sort: val,
        itemsShowing,
        search: search || '',
      })
    }
  }

  const handlePageChange = val => {
    if (page !== val) {
      const q = {
        ...removeEmptyKeys(filter),
        page: val,
        itemsShowing,
        sort,
      }

      if (search) {
        q.search = search
      }

      setPage(val)

      const params = new URLSearchParams(q)

      navigate(`${pathname}?${params.toString()}`)

      topEl.current.focus()
    }
  }

  const onReset = e => {
    const type = e.currentTarget.getAttribute('data-filter')
    onFilter({
      ...filter,
      itemsShowing,
      search: search || '',
      [type]: '',
    })
  }

  const onSearch = async val => {
    const searchResults = await fetch('/api/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ query: val }),
    }).then(res => res.json())

    const filteredSearchResults = searchResults.filter(
      obj => /used/i.test(obj.tag) && !/used\spage/i.test(obj.tag)
    )

    return filteredSearchResults
  }

  const showing = []
  const start = (page - 1) * itemsShowing

  for (let i = start, len = equipment.length; i < len && i < start + itemsShowing; i++) {
    showing.push(equipment[i])
  }

  const totalPages = Math.ceil(equipment.length / itemsShowing)

  let totalItems

  if (page * itemsShowing > equipment.length) {
    totalItems = equipment.length
  } else {
    totalItems = page * itemsShowing
  }

  const totalShowing = page * itemsShowing - (itemsShowing - 1)
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta property='og:title' content={meta.title} />
        <meta property='og:url' content={meta.pageUrl} />
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>

      <Content>
        <TitleBar>
          <TitleContainer>
            <Title>{sanityPromotion.title}</Title>
            {sanityPromotion.subtitle ? (
              <P style={{ marginBottom: 0 }}>{sanityPromotion.subtitle}</P>
            ) : null}
            {sanityPromotion.bodyHtml ? (
              <div dangerouslySetInnerHTML={{ __html: sanityPromotion.bodyHtml }} />
            ) : null}
          </TitleContainer>
          <Sort>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select value={sort} onChange={onSort} name='Sort'>
              {Object.keys(sortOptions).map(option => (
                <option value={option} key={`Sort_${option}`}>
                  {option}
                </option>
              ))}
            </select>
          </Sort>
          <div>
            {search && (
              <FilterTag onClick={onReset} data-filter='search'>
                <FilterTagClose>&times;</FilterTagClose>
                <FilterTagText>Search:&nbsp;{search}</FilterTagText>
              </FilterTag>
            )}
            {Object.keys(filter).map(k => (
              <FilterTag key={k} onClick={onReset} data-filter={k}>
                <FilterTagClose>&times;</FilterTagClose>
                <FilterTagText>
                  {filterTitles[k]}:&nbsp;{formatFilterValue(filter[k], k)}
                </FilterTagText>
              </FilterTag>
            ))}
          </div>
        </TitleBar>
        <ColumnsContainer>
          <FiltersColumn>
            <QuickSearch querystring={isHUT229 ? 'leaseProgramEligible=yes' : null} />
            <Filter
              filter={[
                {
                  heading: 'Location',
                  content: (
                    <LocationFilter
                      equipment={data.equipment}
                      itemsShowing={itemsShowing}
                      search={search || ''}
                      filter={filter}
                      onFilter={onFilter}
                    />
                  ),
                },
                {
                  heading: 'Condition',
                  content: (
                    <ConditionFilter
                      itemsShowing={itemsShowing}
                      search={search || ''}
                      filter={filter}
                      onFilter={onFilter}
                    />
                  ),
                },
                {
                  heading: 'Hours',
                  content: (
                    <HoursFilter
                      itemsShowing={itemsShowing}
                      search={search || ''}
                      filter={filter}
                      onFilter={onFilter}
                    />
                  ),
                },
              ]}
              sort={{
                value: sort,
                options: Object.keys(sortOptions),
                onChange: onSort,
              }}
              desktopWidth={1024}
            />
          </FiltersColumn>
          <ProductsColumn>
            <ShowingBar>
              {equipment.length > 0 && (
                <>
                  Showing {totalShowing}
                  {totalItems > 1 && <span>-{totalItems}</span>}
                  &nbsp;of&nbsp;{equipment.length}
                </>
              )}
              {equipment.length === 0 && <span>No equipment found.</span>}
              <ChangeShowing aria-label='Results per page'>
                Show:{' '}
                {showingOptions.map(val => (
                  <ChangeShowingOption
                    aria-selected={itemsShowing === val}
                    key={val}
                    onClick={setShowing}
                  >
                    {val}
                  </ChangeShowingOption>
                ))}
              </ChangeShowing>
            </ShowingBar>
            <div ref={topEl} tabIndex='-1' />
            {equipment.length > 0 && (
              <>
                {showing.map((product, key) => (
                  <div key={key}>
                    <UsedProductSquare {...product} />
                  </div>
                ))}
              </>
            )}
            {equipment.length === 0 && (
              <NoneFound>No equipment found matching your search.</NoneFound>
            )}
          </ProductsColumn>
        </ColumnsContainer>
      </Content>

      {totalPages > 1 && (
        <StyledPagination
          onPageChange={handlePageChange}
          currentPage={page}
          totalPages={totalPages}
        />
      )}

      <DisclaimersContent>
        <Disclaimers>
          <p>{sanityPromotion.disclaimer}</p>
        </Disclaimers>
      </DisclaimersContent>
    </Layout>
  )
}

const Content = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 0;
  width: 85%;

  @media (min-width: 1024px) {
    padding: 40px 25px;
    width: 1024px;
  }
`

const DisclaimersContent = styled(Content)`
  padding-top: 0;

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`

const TitleBar = styled.div`
  margin: 0 0 30px;
`

const TitleContainer = styled.div`
  @media (min-width: 1024px) {
    display: inline-block;
    margin-right: 3%;
    vertical-align: bottom;
    width: 72%;
  }
`

const Title = styled.h1`
  font-size: 1.75em;
  margin: 0;
`

const FilterTagClose = styled.div`
  border-right: 1px solid #d7d7d7;
  display: inline-block;
  font-size: 1.3em;
  font-weight: 600;
  padding: 6px 8px;
  vertical-align: middle;
`

const FilterTag = styled(UnstyledButton)`
  background-color: #ebebeb;
  color: #7d7d7d;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: lighter;
  line-height: 1;
  margin: 10px 10px 0 0;
  text-decoration: none;

  :hover,
  :focus {
    background-color: #dbdbdb;
    color: #6d6d6d;

    ${FilterTagClose} {
      border-right: 1px solid #c7c7c7;
    }
  }
`

const FilterTagText = styled.div`
  display: inline-block;
  padding: 6px 8px;
  vertical-align: middle;
`

const Sort = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: inline-block;
    vertical-align: bottom;
    width: 25%;
  }
`

const ShowingBar = styled.div`
  border-bottom: 1px solid #d7d7d7;
  color: #999;
  margin-bottom: 40px;
  padding: 5px 0;
  width: 100%;
`

const ChangeShowing = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    float: right;
  }
`

const ChangeShowingOption = styled(UnstyledButton)`
  @media (min-width: 1024px) {
    color: ${props => (props['aria-selected'] ? '#7d7d7d' : '#377539')};
    cursor: ${props => (props['aria-selected'] ? 'default' : 'pointer')};
    display: inline-block;
    font-weight: ${props => (props['aria-selected'] ? '700' : '400')};
    text-decoration: ${props => (props['aria-selected'] ? 'underline' : 'none')};

    :not(:first-of-type) {
      margin-left: 8px;
    }

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

const ColumnsContainer = styled.div`
  @media (min-width: 1024px) {
    ${clearfix}
  }
`

const FiltersColumn = styled.div`
  margin-bottom: 40px;

  @media (min-width: 1024px) {
    ${column('1/4')}
    margin-bottom: 0;
  }
`

const ProductsColumn = styled.div`
  @media (min-width: 1024px) {
    ${column('3/4')}
  }
`

const NoneFound = styled.div`
  background-color: #f6f6f6;
  border: 1px solid #d7d7d7;
  font-size: 1em;
  padding: 10px 20px;
`

const StyledPagination = styled(Pagination)`
  padding: 0 0 40px;
  text-align: center;

  @media (min-width: 800px) {
    padding: 0 0 60px;
  }
`

const getInitialData = ({
  data: {
    sanityPromotion: { eligibleMachineFinderEquipment },
    sanityPromotion,
  },
  location: { pathname },
}) => {
  const equipment = eligibleMachineFinderEquipment

  const meta = {
    title: `${sanityPromotion.title} | Used Equipment | Hutson Inc`,
    pageUrl: `https://www.hutsoninc.com${pathname}`,
  }

  return {
    equipment,
    meta,
    pathname,
    sanityPromotion,
  }
}

const LocationFilter = ({ equipment = [], filter, itemsShowing, onFilter, search }) => {
  let { location } = filter

  const [locations, setLocations] = useState([])

  if (location === undefined) {
    location = 'All Locations'
  }

  useEffect(() => {
    const newLocations = equipment
      .reduce((acc, obj) => {
        const val = obj.location.name
        if (acc.indexOf(val) === -1) {
          acc.push(val)
        }
        return acc
      }, [])
      .sort()
    setLocations(newLocations)
  }, [equipment])

  const select = val => {
    onFilter(
      {
        ...filter,
        itemsShowing,
        search,
        location: val === 'All Locations' ? '' : val,
      },
      true
    )
  }

  const handleChange = e => {
    select(e.target.value)
  }
  return (
    /* eslint-disable-next-line jsx-a11y/no-onchange */
    <select
      className={location !== 'All Locations' ? 'filled' : ''}
      value={location}
      onChange={handleChange}
      name='Location'
    >
      <option value='All Locations'>All Locations</option>
      {locations.map(location => (
        <option key={location} value={location}>
          {location}
        </option>
      ))}
    </select>
  )
}

LocationFilter.propTypes = {
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    })
  ),
  filter: PropTypes.shape({
    location: PropTypes.string,
  }).isRequired,
  itemsShowing: PropTypes.oneOf(showingOptions).isRequired,
  onFilter: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
}

const HoursFilter = ({ filter, itemsShowing, onFilter, search }) => {
  let { maxHours, minHours } = filter
  if (minHours === undefined) {
    minHours = 0
  }
  if (maxHours === undefined) {
    maxHours = 'None'
  }

  const select = (val, type) => {
    const newFilter = {}
    if (type === 'minHours') {
      newFilter.minHours = val
    } else if (type === 'maxHours') {
      if (val === 'None') {
        newFilter.maxHours = ''
      } else {
        newFilter.maxHours = val
      }
    }
    onFilter(
      {
        ...filter,
        ...newFilter,
        itemsShowing,
        search,
      },
      true
    )
  }
  const handleChange = e => {
    const name = e.target.getAttribute('name')
    const val = e.target.value
    select(val, name)
  }
  return (
    <>
      <label>
        Min Hours
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          className={minHours ? 'filled' : ''}
          value={minHours}
          onChange={handleChange}
          name='minHours'
        >
          <option value='0'>0</option>
          <option value='250'>250</option>
          <option value='500'>500</option>
          <option value='1000'>1,000</option>
          <option value='2000'>2,000</option>
          <option value='3000'>3,000</option>
          <option value='4000'>4,000</option>
          <option value='5000'>5,000</option>
        </select>
      </label>
      <label>
        Max Hours
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          className={maxHours !== 'None' ? 'filled' : ''}
          value={maxHours}
          onChange={handleChange}
          name='maxHours'
        >
          <option value='None'>None</option>
          <option value='250'>250</option>
          <option value='500'>500</option>
          <option value='1000'>1,000</option>
          <option value='2000'>2,000</option>
          <option value='3000'>3,000</option>
          <option value='4000'>4,000</option>
          <option value='5000'>5,000</option>
        </select>
      </label>
    </>
  )
}

HoursFilter.propTypes = {
  filter: PropTypes.shape({
    maxHours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minHours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  itemsShowing: PropTypes.oneOf(showingOptions).isRequired,
  onFilter: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
}

const ConditionFilter = ({ filter, itemsShowing, onFilter, search }) => {
  let { condition, ...filterRest } = filter

  if (condition === undefined) {
    condition = 'Any Condition'
  }

  const handleChange = e => {
    const newFilters = {}
    if (e.target.value !== 'any') {
      newFilters.condition = e.target.value
    }
    onFilter(
      {
        ...filterRest,
        ...newFilters,
        itemsShowing,
        search,
      },
      true
    )
  }
  return (
    /* eslint-disable-next-line jsx-a11y/no-onchange */
    <select
      className={condition !== 'Any Condition' ? 'filled' : ''}
      value={condition}
      onChange={handleChange}
      name='Condition'
    >
      <option value='any'>Any Condition</option>
      <option value='New'>New</option>
      <option value='Used'>Used</option>
    </select>
  )
}

ConditionFilter.propTypes = {
  filter: PropTypes.shape({
    state: PropTypes.string,
  }).isRequired,
  itemsShowing: PropTypes.oneOf(showingOptions).isRequired,
  onFilter: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
}

export const pageQuery = graphql`
  query UsedEquipmentPromotionTemplate($_id: String!) {
    sanityPromotion(_id: { eq: $_id }) {
      bodyHtml
      disclaimer
      eligibleMachineFinderEquipment {
        engineHoursMeter
        horsepower
        id
        initialPrice
        isNew
        location {
          name
        }
        manufacturer
        meters {
          type
          value
        }
        model
        modelYear
        modifiedDate
        price
        primaryImage {
          large
          regular
        }
        reducedPrice
        separatorHoursMeter
        slug
        stockNumber
        title
      }
      subtitle
      title
    }
  }
`

export default UsedEquipmentPromotionTemplate
